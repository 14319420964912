import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Hint,
  Icon,
  Image,
  ItemList,
  ItemListDropdown,
  List,
  Loading,
  Text,
} from "react-web-skeleton";
import Logo from "../../../assets/img/logo/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { NavbarPanel } from "react-web-skeleton/dist/components/Navbar";
import { checkRoleId, clearLocalStorageData } from "utils/authUtils";
import { useMutation, useQuery } from "react-query";
import { createDatas, fetchDatas } from "utils/Main/apiMainUtils";
import { TimeAgo } from "helper/globalFunction";

const NavbarLayout = () => {
  const name = localStorage.getItem("name");
  const navigate = useNavigate();
  const [count, setCount] = useState(false);

  const handleLogout = () => {
    clearLocalStorageData();
    navigate("/");
  };

  const { data, isLoading, isFetching } = useQuery(
    ["fetchNotification"],
    () => fetchDatas("notification"),
    { enabled: checkRoleId() ? true : false }
  );

  const mutation = useMutation(
    (updatedData) => createDatas("notification/read"),
    {}
  );

  const handleSubmit = async () => {
    setCount(false);
    mutation.mutate();
  };

  useEffect(() => {
    if (data && data.length > 0) {
      if (data?.[0]?.is_read === 0) {
        setCount(true);
      }
    }
  }, [data]);

  return (
    <div className="wrapper flex-row items-center justify-between py-0 md:py-4">
      <Link to="/" className="my-auto">
        <Image src={Logo} className="img-navbar" alt="" />
      </Link>
      {name ? (
        <NavbarPanel className="border-none shadow-none">
          <div className="flex items-center wrapper-menu-user gap-x-8">
            <Dropdown
              className="absolute right-28 flex-col min-w-[300px] max-w-sm scroll-m-0"
              elementAction={
                <Icon
                  iconOnly
                  label="bell"
                  number={count && "!"}
                  size="small"
                  onClick={handleSubmit}
                />
              }
            >
              <div className="h-96 overflow-y-auto">
                <ItemListDropdown className="title text-center">
                  <Text size="l" weight="semibold">
                    Notifikasi
                  </Text>
                </ItemListDropdown>
                {isLoading ||
                  (isFetching && (
                    <div
                      style={{ width: "24rem" }}
                      className={`flex items-center justify-center transition-all duration-500 ${
                        isLoading || isFetching ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      <Loading size="xl" />
                    </div>
                  ))}
                {data && data?.length > 0 ? (
                  data?.map((list, index) => {
                    return (
                      <ItemListDropdown
                        className={`notif-item ${
                          list.is_read ? "bg-slate-0" : "bg-slate-200"
                        }  text-left transition-all duration-500 ${
                          isLoading || isFetching ? "opacity-0" : "opacity-100"
                        }`}
                        key={index}
                      >
                        <div className="flex flex-row cursor-pointer">
                          <div
                            className="flex flex-column"
                            style={{
                              marginLeft: "8px",
                            }}
                          >
                            <Text size="m" weight="medium">
                              {list.title}
                            </Text>
                            <Text
                              size="s"
                              weight="medium"
                              className="text-[#787878] mt-1 mb-2"
                            >
                              {list.description}
                            </Text>
                            <Text size="s" weight="regular">
                              <TimeAgo timestamp={list?.created_at || ""} />
                            </Text>
                          </div>
                        </div>
                      </ItemListDropdown>
                    );
                  })
                ) : (
                  <div className="mx-5">
                    <Text size="m" weight="semibold">
                      Tidak ada notifikasi
                    </Text>
                  </div>
                )}
              </div>
            </Dropdown>

            <Dropdown
              className="absolute right-28 flex-col min-w-[200px] max-w-sm scroll-m-0"
              elementAction={
                <>
                  <List className="hidden md:block text-left cursor-pointer">
                    <ItemList>
                      <Text size="m" weight="semibold">
                        {name}
                      </Text>
                      <Hint size="m" weight="regular">
                        {localStorage.getItem("email")}
                      </Hint>
                    </ItemList>
                  </List>
                  <Icon
                    className="md:hidden"
                    iconOnly
                    label="menu"
                    size="small"
                  />
                </>
              }
            >
              <div>
                <ItemListDropdown
                  className={`notif-item w-[15rem] bg-white rounded-sm shadow-md border absolute ${
                    name.length < 10 ? "right-[-3.5rem]" : ""
                  }`}
                >
                  <List className="text-left">
                    <ItemList>
                      <Text size="s" weight="semibold">
                        {name}
                      </Text>
                      <Hint size="s" weight="regular">
                        {localStorage.getItem("email")}
                      </Hint>
                    </ItemList>
                  </List>
                  <List className="text-left my-4 cursor-pointer">
                    <ItemList>
                      <Text size="s" weight="semibold">
                        <Link to={"/pengaturan/profil"}>Pengaturan</Link>
                      </Text>
                    </ItemList>
                  </List>
                  <List className="text-left my-4 cursor-pointer">
                    <ItemList>
                      <Text size="s" weight="semibold">
                        <Link to={"/pengaturan/portfolio"}>Portfolio</Link>
                      </Text>
                    </ItemList>
                  </List>
                  <List className="text-left my-4 cursor-pointer">
                    <ItemList>
                      <Text size="s" weight="semibold">
                        <Link to={"/pengaturan/wallet"}>Wallet</Link>
                      </Text>
                    </ItemList>
                  </List>
                  <List className="text-left cursor-pointer text-red-700">
                    <ItemList>
                      <Text size="s" weight="semibold" onClick={handleLogout}>
                        Keluar
                      </Text>
                    </ItemList>
                  </List>
                </ItemListDropdown>
              </div>
            </Dropdown>
          </div>
        </NavbarPanel>
      ) : (
        <>
          <div className="flex space-x-5">
            <Button
              borderRadius="8px"
              label="Masuk"
              mode="primary"
              onClick={() => {
                navigate("/masuk");
              }}
              textColor="white"
              type="secondary"
              className="w-full"
            />
            <Button
              borderRadius="8px"
              label="Daftar"
              mode="primary"
              onClick={() => {
                navigate("/daftar");
              }}
              textColor="white"
              type="primary"
              className="w-full"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default NavbarLayout;
