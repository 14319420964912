import React, { Suspense } from "react";
import { checkRoleId } from "utils/authUtils";
import { Outlet } from "react-router-dom";
import NavbarLayout from "components/template/Navbar";
import FooterLayout from "components/template/Footer";
import { LoadingScreen } from "components/UI/common/atoms/Loading";

const RootTemplate = (props) => {
  return (
    <div className="flex flex-col h-screen">
      <NavbarLayout />
      <div className="container mx-auto flex-grow px-5">
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </div>
      <FooterLayout />
    </div>
  );
};

export default RootTemplate;
