import { checkAuthentication, clearLocalStorageData } from "../authUtils";

const token = checkAuthentication();

export const fetchDatas = async (path, currentPage, itemsPerPage) => {
  const URL = `${process.env.REACT_APP_BACKEND_URL}/customer/${path}`;
  const response = await fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    if (response?.status === 401) {
      clearLocalStorageData();
      window.location.href = "/masuk";
      return;
    }
    throw new Error("Fetching Error");
  }
  return await response.json();
};

export const createDatas = async (path, data) => {
  const URL = `${process.env.REACT_APP_BACKEND_URL}/customer/${path}`;
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    if (response?.status === 401) {
      clearLocalStorageData();
      window.location.href = "/masuk";
      return;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || "An error occurred.");
    }
  }
  return await response.json();
};

export const createDataForms = async (path, data) => {
  const URL = `${process.env.REACT_APP_BACKEND_URL}/customer/${path}`;
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  });

  if (!response.ok) {
    if (response?.status === 401) {
      clearLocalStorageData();
      window.location.href = "/masuk";
      return;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || "An error occurred.");
    }
  }
  return await response.json();
};

export const createPayment = async (userData) => {
  console.log("as", userData);
  const URL = `${process.env.REACT_APP_BACKEND_URL}/customer/payment/create`;
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "An error occurred.");
  }

  return response.json();
};

export const submitPayment = async (updatedData) => {
  const URL = `${process.env.REACT_APP_BACKEND_URL}/customer/payment/submit`;
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: updatedData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "An error occurred.");
  }

  return response.json();
};
