import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { AuthWrapper, Image } from "react-web-skeleton";
import LoginImage from "../../../assets/img/login/Login.png";
import MaskImage from "../../../assets/img/login/Mask.png";
import LogoImage from "../../../assets/img/login/Logo.png";

const AuthTemplate = () => {
  return (
    <AuthWrapper>
      <div className="flex items-center justify-center min-h-screen">
        <div className="hidden md:flex md:flex-wrap md:w-1/2 bg-login">
          <Image className="login-image" src={LoginImage} />
          <Image className="mask-image" src={MaskImage} />
          <Image className="logo-image" src={LogoImage} />
        </div>
        <div className="flex flex-wrap md:w-1/2 items-center h-screen">
          <Suspense fallback="loading...">
            <Outlet />
          </Suspense>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default AuthTemplate;
