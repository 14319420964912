import { formatDistanceToNow } from "date-fns";
import { some, isEmpty } from "lodash";
import moment from "moment";
export const objectToParams = (obj) => {
  if (!obj) return "";
  var str = Object.keys(obj)
    .filter((key) => obj[key])
    .map(function (key) {
      return key + "=" + obj[key];
    })
    .join("&");
  return str;
};

export const ValidationPayload = (obj) => {
  let Errors = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]?.length === 0 || !obj[key]) {
      Errors = { ...Errors, [key]: `${key} tidak boleh kosong!` };
    }
  });
  return { Errors, isValid: Object.keys(Errors).length === 0 };
};

export const formatDate = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return new Intl.DateTimeFormat("id-ID", options)
    .format(new Date(date))
    .replace("pukul", "")
    .trim();
};

export const formatDateOnly = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return new Intl.DateTimeFormat("id-ID", options)
    .format(new Date(date))
    .trim();
};

export const formatDaysAgo = (date) => {
  var a = moment();
  var b = moment(date);

  var diff = b.diff(a, "days");

  if (diff < 0) {
    diff = 0;
  }
  return diff;
};

export const formatYear = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return new Intl.DateTimeFormat("id-ID", options)
    .format(new Date(date))
    .replace("pukul", "")
    .trim();
};

export const profit = (grade, slot_qty, estimated_selling_price, price) => {
  var profit = estimated_selling_price - price;
  if (grade === "A") {
    profit = profit * 0.6;
  } else if (grade === "B") {
    profit = profit * 0.5;
  } else if (grade === "C") {
    profit = profit * 0.4;
  } else {
    profit = profit * 0;
  }

  profit = (profit / 10) * slot_qty;

  return profit;
};

export const formatAsRupiah = (number) => {
  const formatRupiah = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  return formatRupiah.format(number);
};

export const formatDateTimeToTime = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  const time = dateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  return time;
};

export function TimeAgo({ timestamp }) {
  if (!timestamp) return;
  const timeAgo = formatDistanceToNow(new Date(timestamp), { addSuffix: true });

  return <span>{timeAgo}</span>;
}

export const separator = (value) => {
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  return addCommas(removeNonNumeric(value.toString().replace(/^0+/, "")));
};

export const labelStatus = (status) => {
  if (status === "Menunggu Konfirmasi") {
    return {
      bg: "#FFF1D8",
      color: "text-[#805D1D]",
    };
  } else if (status === "Menunggu Pembayaran") {
    return {
      bg: "#fcc25b",
      color: "text-[#332409]",
    };
  } else if (status === "Selesai") {
    return {
      bg: "#E6F7EF",
      color: "text-[#02562F]",
    };
  } else if (status === "Gagal" || status === "Kedaluwarsa") {
    return {
      bg: "#FFD8D7",
      color: "text-[#801F1C]",
    };
  }
};
