import { lazy } from "react";
import { Route, Routes as Switch } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";

import Template from "components/template";
import AuthTemplate from "components/template/Login";
// e.Page Error

const Login = lazy(() => import("components/page/Auth/Login"));
const Register = lazy(() => import("components/page/Auth/Register"));
const MainPage = lazy(() => import("components/page/MainPage"));
const DetailUnitPage = lazy(() => import("components/page/DetailUnit"));
const CheckoutPage = lazy(() => import("components/page/Checkout"));
const PaymentPage = lazy(() => import("components/page/Payment"));
const WaitingConfirmationPage = lazy(() =>
  import("components/page/WaitingConfirmation")
);
const WaitingConfirmationTopupPage = lazy(() =>
  import("components/page/WaitingConfirmationTopup")
);
const WaitingPaymentTopupPage = lazy(() =>
  import("components/page/WaitingPaymentTopup")
);
const ProfilePage = lazy(() => import("components/page/Profile"));
const DetailPortfolioPage = lazy(() =>
  import("components/page/DetailPortfolio")
);

export default function Routes() {
  return (
    <Switch>
      <Route element={<AuthTemplate />}>
        <Route element={<Login />} path="masuk" index />
        <Route element={<Register />} path="daftar" />
      </Route>
      <Route element={<Template />}>
        {/* <Route element={<Navigate replace to="/" />} index /> */}
        <Route element={<MainPage />} path="/" index />
        <Route element={<DetailUnitPage />} path="/detail-unit/:id" />
        <Route element={<ProtectedRoute allowedRoles={"Member"} />}>
          <Route element={<CheckoutPage />} path="/checkout" />
          <Route element={<PaymentPage />} path="payment/:id" />
          <Route
            element={<WaitingConfirmationPage />}
            path="waiting-confirmation/:id"
          />
          <Route
            element={<WaitingConfirmationTopupPage />}
            path="topup/waiting-confirmation/:id"
          />
          <Route
            element={<WaitingPaymentTopupPage />}
            path="topup/waiting-payment/:id"
          />
          <Route element={<ProfilePage />} path="pengaturan/:type" />
          <Route
            element={<DetailPortfolioPage />}
            path="pengaturan/portfolio/:id"
          />
        </Route>
      </Route>
    </Switch>
  );
}
