import React from "react";
import { Icon, Image } from "react-web-skeleton";
import Logo from "../../../assets/img/logo/logo.png";

const FooterLayout = (props) => {
  return (
    <div className="footer bg-white">
      <hr></hr>
      <div className="container mx-auto">
        <Image src={Logo} className="h-[40px] my-3 md:my-8 mx-auto md:mx-0" />
        <hr></hr>
        <div className="md:flex flex-row justify-between my-8 text-center">
          <div className="md:order-2">
            <div className="flex flex-row gap-[12px] justify-center">
              <Icon
                label="social-media"
                className="bx bxl-linkedin"
                mode="primary"
                shape="square"
                size="small"
                type="light"
              />
              <Icon
                label="social-media"
                className="bx bxl-facebook"
                mode="primary"
                shape="square"
                size="small"
                type="light"
              />
              <Icon
                label="social-media"
                className="bx bxl-instagram"
                mode="primary"
                shape="square"
                size="small"
                type="light"
              />
              <Icon
                label="social-media"
                className="bx bxl-youtube"
                mode="primary"
                shape="square"
                size="small"
                type="light"
              />
            </div>
          </div>
          <div className="mt-2 md:order-1">© 2023 JoinanAja. All rights reserved.</div>
        </div>
      </div>
    </div>
  );
};

export default FooterLayout;
